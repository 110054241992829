import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { navigate } from "gatsby-link";
import api from "../../api";
import { AuthContext } from "../../context/GlobalContextProvider";
import { Button } from "antd";
import PredioComponent from "../generics/predios.js";

export default function BivverClub() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState();
  const context = useContext(AuthContext);
  useEffect(() => {
    getData();
    getPage();
  }, []);
  const getData = async () => {
    var response = await api.get("/predios?classe=BivverBClub");
    setData(response.data);
  };
  const getPage = async () => {
    var response = await api.get("/bivver-club");
    setPage(response.data);
  };
  return (
    <Layout
      aboveFooter
      titlePage="Bivver Club"
      pageDesctiption={page?.conteudo}
      loading={!page}
    >
      <SEO
        keywords={[`Bivver`, `Empreendimentos`, `imobiliários`, `praia`, `prédios`]}
        title="Bivver Club"
      />
      {context?.user && (
        <Button
          className="mb-10"
          style={{ borderColor: "orange", color: "orange" }}
          danger
          onClick={() => navigate(`/edicao/bivverClub`)}
        >
          Editar
        </Button>
      )}
      <div>
        <PredioComponent buildings={data} />
      </div>
    </Layout>
  );
}
